<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <a-button
        style="margin-right: 15px"
        type="primary"
        class="org back-btn"
        @click="$router.push({ name: 'documents' })"
      >
        <i class="t-transition icons icon-chevron-left" />
        Назад
      </a-button>
      <a-button
        style="margin-left: auto"
        type="primary"
        @click="$router.push({ name: 'kyh-create' })"
      >
        <a-icon type="plus" />{{ $t("Add") }}
      </a-button>
    </a-row>

    <a-row type="flex" class="table__filtrs">
      <h2>{{ title[$i18n.locale] }}</h2>
      <a-button
        :disabled="cardsIsEmpty"
        type="primary"
        style="margin: 0 0 15px auto"
        @click="removeItem(selectedPosts)"
      >
        <a-icon class="action-btns" type="delete" />
        Удалить
      </a-button>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="list.results"
      :loading="loading"
      :pagination="false"
      class="faqs"
    >
      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
        <span
          class="td-post-item"
          @click="$router.push({ name: 'khy-detail', params: { id: item.id } })"
        >
          <b>{{ item.id }}</b>
        </span>
      </template>

      <template slot="t_title" slot-scope="item">
        <span
          class="text-center td-post-item"
          style="cursor: pointer; display: block; width: 100%; text-align: left"
          @click="$router.push({ name: 'kyh-detail', params: { id: item.id } })"
        >
          {{ item.title || "Не заполнено" }}
        </span>
      </template>

      <template slot="slug" slot-scope="item">
        {{ item.slug }}
      </template>

      <template slot="link" slot-scope="item">
        <a :href="item.url" target="_blank">{{ item.url }}</a>
      </template>

      <template slot="number_ministry" slot-scope="item">
        {{ item.number_ministry }}
      </template>

      <template slot="status" slot-scope="item">
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template slot="invalidated" slot-scope="item">
        <a-tag :color="item.is_invalidated ? 'volcano' : 'geekblue'">
          {{ item.is_invalidated ? "Да" : "Нет" }}
        </a-tag>
      </template>

      <template slot="date" slot-scope="item">
        {{ moment(item.date).format("LL") }}
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          style="margin-right: 10px"
          type="primary"
          @click="$router.push({ name: 'kyh-detail', params: { id: item.id } })"
        >
          <a-icon class="action-btns" type="edit" />
        </a-button>
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem(item.id)"
        >
          <a-button style="margin-right: 10px" type="danger">
            <a-icon class="action-btns" type="delete" />
            <!--{{ $t("Delete") }}-->
          </a-button>
        </a-popconfirm>
      </template>
    </a-table>

    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="perPage"
        :current="page"
        :default-page-size="perPage"
        :total="+list.count || 0"
        @change="toPage"
      />
    </a-row>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      title: {
        oz: "Кучини йукотган хужжатлар",
        uz: "Kuchini yo'qotgan hujjatlar",
        ru: "Не действующие НПА",
        en: "kyh"
      },
      loading: false,
      list: {
        results: [],
        count: 0
      },
      perPage: 20,
      page: 1,
      offset: 0,
      columns: [
        {
          title: "ID",
          key: "id",
          width: 80,
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("TableHead"),
          key: "t_title",
          width: 500,
          scopedSlots: { customRender: "t_title" }
        },
        {
          title: "Slug",
          key: "slug",
          width: 500,
          scopedSlots: { customRender: "slug" }
        },
        {
          title: this.$t("Link"),
          key: "link",
          width: 500,
          scopedSlots: { customRender: "link" }
        },
        {
          title: "№",
          key: "number_ministry",
          width: 100,
          scopedSlots: { customRender: "number_ministry" }
        },
        {
          title: this.$t("AcceptDate"),
          key: "date",
          width: 220,
          scopedSlots: { customRender: "date" }
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: 100,
          scopedSlots: { customRender: "status" }
        },
        {
          title: this.$t("HasExpired"),
          key: "invalidated",
          width: 120,
          scopedSlots: { customRender: "invalidated" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: 220,
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: 220,
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          // width: 80,
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      selectedRowKeys: [],
      selectedPosts: [],
      cardsIsEmpty: true
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    toPage(arg) {
      this.$router.push({ query: { page: arg } })
    },
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item === +itemId)
      if (f !== -1) {
        this.selectedPosts.splice(f, 1)
        this.cardsIsEmpty = false
      } else {
        this.selectedPosts.push(itemId)
        this.cardsIsEmpty = false
      }

      if (this.selectedPosts.length === 0) {
        this.cardsIsEmpty = true
      }
    },
    async fetchData() {
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.perPage
      try {
        let res = await this.$api.get(
          "/admin/current-regulation/regulations/list/",
          {
            params: {
              offset: this.offset,
              limit: this.perPage,
              is_invalidated: true
            }
          }
        )
        if (res && res.data) {
          this.$set(this, "list", {
            results: (res.data && res.data.results) || [],
            count: res.data && res.data.count
          })
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }
      this.loading = false
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const res = await this.$api.delete(
              `/admin/current-regulation/regulations/${i}/delete/`
            )
            if (res) {
              this.$message.success("Успешно удалено")
            }
          } catch (e) {
            this.$message.success("Ошибка при удалении")
            this.$sentry.captureMessage(e)
          }
        }
      } else {
        try {
          const res = await this.$api.delete(
            `/admin/current-regulation/regulations/${id}/delete/`
          )
          if (res) {
            this.$message.success("Успешно удалено")
          }
        } catch (e) {
          this.$message.success("Ошибка при удалении")
          this.$sentry.captureMessage(e)
        }
      }
      await this.fetchData()
      this.selectedCards = []
      this.cardsIsEmpty = true
    }
  }
}
</script>
